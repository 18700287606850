import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEL from './translationEL.json';
import translationEN from './translationEN.json';

// Configure i18next
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      el: { translation: translationEL }, // Greek translations
      en: { translation: translationEN }, // English translations
    },
    lng: localStorage.getItem('language') || 'el', // default language
    fallbackLng: 'el', // Fallback language if translation is missing
    interpolation: {
      escapeValue: false, // React already protects against XSS
    },
  });

export default i18n;
