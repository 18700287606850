import moment from 'moment';
import { updateUserCoordinates } from '../fetchers/navigator';
import { User, useAuthContext } from '../components/context/Auth';
import { useState } from 'react';

const useEnableTracking = () => {
  const { setUser } = useAuthContext();
  const [permissionBlocked, setPermissionBlocked] = useState<boolean>(false);

  const enableTracking = (userInfo: User) => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUser({
            ...userInfo as User,
            latitude,
            longitude,
            location_last_updated: moment(new Date()).toLocaleString(),
            has_accepted_browser_position: true
          });
          updateUserCoordinates(latitude, longitude);
        },
        (error) => {
          console.error('position has error: ', error);
          setUser({
            ...userInfo as User,
            latitude: null,
            longitude: null,
            has_accepted_browser_position: true
          });
          setPermissionBlocked(true);
          updateUserCoordinates(null, null);
        },
        {
          enableHighAccuracy: true, // Request higher accuracy
          maximumAge: 0 // Prevent caching of the position
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setUser({
        ...userInfo as User,
        latitude: null,
        longitude: null,
        has_accepted_browser_position: true
      });
      updateUserCoordinates(null, null);
    }
  };

  return { enableTracking, permissionBlocked };
};

export default useEnableTracking;