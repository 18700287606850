import { FilterAlt, Work } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Theme from '../../Themes/main.theme';
import { ScrollbarBox } from '../../components/ScrollbarBox/ScrollbarBox';
import { IBasicJSON } from '../../interfaces/generals.interface';
import { findParams, sortedSpecialties } from '../../utils/helperFunctions';

export const FilterOptions = ({ specialties }: { specialties: IBasicJSON[] }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const specialtyParams: string[] = searchParams
      .getAll('specialty')[0]
      ?.split(',');

    if (!specialtyParams) searchParams.set('specialty', event.target.name);
    else if (!specialtyParams.includes(event.target.name))
      searchParams.set(
        'specialty',
        [...specialtyParams, event.target.name].join(',')
      );
    else if (specialtyParams.includes(event.target.name)) {
      const indexOfSpecialty = specialtyParams.indexOf(event.target.name);
      specialtyParams.splice(indexOfSpecialty, 1);

      specialtyParams.length
        ? searchParams.set('specialty', specialtyParams.join(','))
        : searchParams.delete('specialty');
    }

    navigate(`?${searchParams.toString()}`);
  };

  const handleFavouiteParam = (event: React.ChangeEvent<HTMLInputElement>) => {
    const favouritesParam: string[] = searchParams
      .getAll('favourites')[0]
      ?.split(',');

    if (!favouritesParam) searchParams.set('favourites', 'true');
    else searchParams.delete('favourites');

    navigate(`?${searchParams.toString()}`);
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box>
        <Box 
          display='flex' 
          alignItems='end' 
          mb={2} 
          gap={0.5}
        >
          <FilterAlt
            sx={{
              border: 1,
              borderRadius: 1,
              borderColor: Theme.hoverColor?.main,
            }}
            htmlColor={Theme.palette.secondary.main}
          />
          <Typography variant='h4'>{t('Φίλτρα')}</Typography>
        </Box>
        <FormControlLabel
          key='favourites'
          label={t('Αγαπημένα')}
          name='favourites'
          sx={{
            pr: 2,
            pl: 0.5,
            borderRadius: 1,
            background:
              searchParams.get('favourites') === 'true'
                ? Theme.hoverColor?.background
                : 'transparent',
            '&:hover': {
              '& .MuiFormControlLabel-label': {
                color: Theme.hoverColor?.main,
              },
              '& .MuiCheckbox-root': {
                color: Theme.hoverColor?.main,
              },
            },
          }}
          control={
            <Checkbox
              checked={searchParams.get('favourites') === 'true'}
              onChange={handleFavouiteParam}
              sx={{
                '&:hover': {
                  color: Theme.hoverColor?.main,
                },
              }}
            />
          }
        />
      </Box>
      <Box>
        <Box 
          display='flex' 
          alignItems='end' 
          mb={2}
          gap={0.5}
        >
          <Work
            sx={{
              border: 1,
              borderRadius: 1,
              borderColor: Theme.hoverColor?.main,
            }}
            htmlColor={Theme.palette.secondary.main}
          />
          <Typography variant='h4'>{t('Ειδικότητες')}</Typography>
        </Box>
        <Box 
          display='flex' 
          flexDirection='column' 
          gap={0.5}
        >
          {sortedSpecialties(specialties, i18n).map((specialty) => {
            return (
              <FormControlLabel
                key={specialty.value}
                label={i18n.language === 'el'
                  ? specialty.key
                  : specialty.description_en}
                name={specialty.code_name ?? specialty.key}
                sx={{
                  pr: 2,
                  pl: 0.5,
                  borderRadius: 1,
                  background: findParams(
                    specialty.code_name ?? specialty.key,
                    searchParams
                  )
                    ? Theme.hoverColor?.background
                    : 'transparent',
                  '&:hover': {
                    '& .MuiFormControlLabel-label': {
                      color: Theme.hoverColor?.main,
                    },
                    '& .MuiCheckbox-root': {
                      color: Theme.hoverColor?.main,
                    },
                  },
                }}
                control={
                  <Checkbox
                    checked={findParams(
                      specialty.code_name ?? specialty.key,
                      searchParams
                    )}
                    onChange={handleChange}
                    sx={{
                      '&:hover': {
                        color: Theme.hoverColor?.main,
                      },
                    }}
                  />
                }
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}; 
const SideMenu = ({ specialties }: { specialties: IBasicJSON[] }) => {
  return (
    <ScrollbarBox
      maxHeight='80vh' 
      mr={2}
    >
      <FilterOptions specialties={specialties}/>
    </ScrollbarBox>
  );
};

export default SideMenu;
