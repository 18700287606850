import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import customIcon from './customIcon';
import selfIcon from './selfIcon';
import { ICoordinates } from './types';
import { Link } from 'react-router-dom';
import { Typography, useMediaQuery } from '@mui/material';
import Theme from '../../Themes/main.theme';
import { useAuthContext } from '../context/Auth';
import { useTranslation } from 'react-i18next';

const MapWithMultipleMarkers = (props: { coordinates: ICoordinates[] }) => {
  const matchesMD = useMediaQuery(() => Theme.breakpoints.up('md'));
  const { user } = useAuthContext();
  
  const { t } = useTranslation();

  const map_center = {
    //center of Patras
    lat: 38.246639,
    lng: 21.734573,
  };

  const mapKey = `${map_center.lat}-${map_center.lng}`;
  return (
    <MapContainer
      key={mapKey} // Use the key to force re-render when coordinates change
      center={map_center}
      zoom={13}
      style={{ 
        height: matchesMD
          ? '400px'
          : '100%', 
        width: '100%' 
      }}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        props.coordinates.map((coord, index) => {
          return (
            (coord.latitude && coord.longitude) && 
            <Marker
              key={index}
              position={{lat: coord.latitude, lng: coord.longitude}} 
              icon={coord.info?.email === user?.email
                ? selfIcon
                : customIcon
              }
            >
              <Popup>
                <Typography variant='body1'>
                  {coord.info?.name}
                </Typography>
                <Typography fontWeight='bold'>
                  {coord.info?.specialty}
                </Typography>
                {coord.info?.description}
                <Typography>
                  <Link to={coord.info?.email === user?.email
                    ? '/profile'
                    : `/users/${coord.info?.id}`
                  }>
                    {t('Μετάβαση στο προφίλ')}
                  </Link>
                </Typography>
              </Popup>
            </Marker>
          );
        })
      }
    </MapContainer>
  );
};

export default MapWithMultipleMarkers;
