import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

// Create an instance of Axios
const instance = axios.create();
// Add a request interceptor
instance.interceptors.request.use(
  (config) => {

    config.baseURL = process.env.REACT_APP_BACKEND_URL;
    config.withCredentials = true;
    
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Modify the response data here, if needed
    return response;
  },
  (error) => {
    // Handle response error
    const { pathname } = window.location;
    if (axios.isAxiosError(error)) {
      const language = localStorage.getItem('language');
      error.response?.status === 500
        ? enqueueSnackbar(language === 'en'
          ? 'Something went wrong! Try again later!'
          : 'Κάτι πήγε στραβά! Προσπαθήστε πάλι αργότερα!'
        , { variant: 'error' })
        : error.config?.url !== '/users/profile' && 
          enqueueSnackbar(error.response?.data.message, { variant: 'error' });

      if (error.response?.status === 401 && pathname !== '/login' && pathname !== '/register') {
        window.location.href = '/login';
      }
    }
    if (axios.isCancel(error)) {
      console.info('Request cancelled:', error.message);
    }
    return Promise.reject(error);
  }
);

export default instance;