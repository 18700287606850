/* eslint-disable react-hooks/exhaustive-deps */
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AsyncBackdrop from '../components/Backdrop/AsyncBackdrop';
import ClosableAlert from '../components/CloseableAlert/ClosableAlert';
import MapWithMultipleMarkers from '../components/Map/MapWithMultipleMarkers';
import Modal from '../components/Modal/Modal';
import ProfessionalCard from '../components/ProfessionalCard/ProfessionalCard';
import { useAuthContext } from '../components/context/Auth';
import { useFirebaseContext } from '../components/context/FirebaseAuth';
import useSpecialties from '../hooks/useSpecialties';
import { IProfessional } from '../interfaces/professionals.interface';
import ResponsiveMenu from '../layout/ResponsiveMenu/ResponsiveMenu';
import SideMenu from '../layout/SideMenu/SideMenu';
import HttpService from '../services/HttpService';
import { startConversation } from '../utils/helperFunctions';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t, i18n } = useTranslation();

  const { user, isAuthenticated } = useAuthContext();
  const firebaseUser = useFirebaseContext();

  const [chips, setChips] = useState<string[]>([]);
  const [professionals, setProfessionals] = useState<IProfessional[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  const { specialties, loading: specialtiesLoading } = useSpecialties();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // functions based on params, fetchers and chips
  const handleDeleteChips = (chip: string) => {
    const specialtyParams: string[] = searchParams
      .getAll('specialty')[0]
      ?.split(',');
    const indexOfSpecialty = specialtyParams.indexOf(chip);
    specialtyParams.splice(indexOfSpecialty, 1);
    setChips(specialtyParams);

    specialtyParams.length
      ? searchParams.set('specialty', specialtyParams.join(','))
      : searchParams.delete('specialty');

    navigate(`?${searchParams.toString()}`);
  };

  const fetchProfessionals = async () => {
    setIsLoading(true);
    try {
      const response = await HttpService.get(
        `/users?${searchParams.toString()}`,
        {
          params: {
            lat: user?.latitude,
            lng: user?.longitude,
          },
        }
      );
      setProfessionals(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChipsFromParams = () => {
    if (searchParams.size === 0) return setChips([]);

    const specialtiesParams = searchParams.getAll('specialty')[0]?.split(',');
    setChips(mapSpecialtyParmasToArray(specialtiesParams));
  };

  const mapSpecialtyParmasToArray = (specialtiesParams: Array<string>) => {
    return specialtiesParams
      ?.map(
        (specialtyParam) => {
          const specialtyFound = 
            specialties.find((spec) => (spec.code_name || spec.key) === specialtyParam);

          return (
            i18n.language === 'el'
              ? specialtyFound?.key
              : specialtyFound?.description_en
          ) ?? '';
        }
      )
      .filter(Boolean);
  };

  //start conversation function
  const sendMessage = async (email: string) => {
    try {
      setOpenBackdrop(true);
      await startConversation(
        email,
        (conversationID: string) =>
          navigate(`/messenger?mid=${conversationID}`),
        firebaseUser
      );
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar({ variant: 'error', message: error.message });
    } finally {
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    handleChipsFromParams();
    fetchProfessionals();
  }, [searchParams, user?.latitude]);

  useEffect(() => {
    handleChipsFromParams();
  }, [specialties]);

  const Map = (
    <MapWithMultipleMarkers
      coordinates={
        [{
          latitude: user?.latitude,
          longitude: user?.longitude,
          info: {
            id: 0,
            description: user?.description ??'',
            specialty: user?.specialties?.description ?? '',
            email: user?.email ?? '', 
            name: !user?.first_name && !user?.last_name
              ? user?.email ?? ''
              : `${user?.first_name || ''} ${user?.last_name || ''}`,
          },
        },
        ...professionals.map((prof) => {
          return {
            latitude: prof.latitude,
            longitude: prof.longitude,
            info: {
              id: prof.id,
              description: prof?.description,
              specialty: prof?.specialty,
              email: prof?.email, 
              name:
              !prof?.first_name && !prof?.last_name
                ? prof?.email
                : `${prof?.first_name || ''} ${prof?.last_name || ''}`,
            },
          };
        })]
      }
    />
  );

  useEffect(() => {
    if (!user?.is_available && !localStorage.getItem('show-available-alert')) {
      localStorage.setItem('show-available-alert', 'true');
    }
  }, []);

  if (isLoading && specialtiesLoading && isAuthenticated) {
    return <>Loading...</>;
  }

  return (
    <Box display='flex'>
      <Box>
        <Box display={{ xs: 'none', sm: 'flex' }} mt={2}>
          <SideMenu specialties={specialties} />
        </Box>
        <Box display={{ sm: 'none' }}>
          <ResponsiveMenu specialties={specialties} />
        </Box>
      </Box>
      <Box width='100%'>
        <Typography 
          variant='h2' 
          textAlign={{ xs: 'center', sm: 'left' }} 
        >
          {t('Αναζήτηση επαγγελματιών')}
        </Typography>
        <Box 
          display='flex' 
          flexDirection={{xs: 'column', sm: 'row'}}
          alignItems='center' 
          gap={1} 
          mb={2} 
        >
          <Box flex='1'>
            <Typography 
              variant='body2' 
              color='text.secondary' 
            >
              {t('Ενεργοί διαθέσιμοι χρήστες')} {user?.latitude && user?.longitude && t('σε ακτίνα 2 χιλιομέτρων')}
            </Typography>
          </Box>
          <Button 
            variant='outlined'
            endIcon={<MapIcon color='primary'/>} 
            onClick={() => setOpenMap(true)}
          >
            <Typography 
              variant='h6' 
              color='text.secondary' 
            >
              {t('Προβολή')}
            </Typography>
          </Button>
        </Box>
        <Box display='flex' minHeight='80%'>
          <Box 
            display='flex' 
            flexDirection='column' 
            width='100%'
          >
            {!user?.is_available && localStorage.getItem('show-available-alert') === 'true' && (
              <ClosableAlert
                severity='warning'
                text={t('Δεν είστε ορατός! Αλλάξτε από το προφίλ σας!')}
                onClose={() =>
                  localStorage.setItem('show-available-alert', 'false')
                }
              />
            )}
            {chips?.length > 0 && (
              <Box 
                display={{ xs: 'none', md: 'flex' }}
                flexWrap='wrap'
                gap={1}
                mb={2}
              >
                {chips.map((chip) => {
                  return (
                    <Chip
                      key={chip}
                      sx={{ color: 'black', backgroundColor: 'white' }}
                      label={chip}
                      variant='outlined'
                      onDelete={() => handleDeleteChips(chip)}
                    />
                  );
                })}
              </Box>
            )}

            {professionals.length === 0 
              ? <Box 
                display='flex' 
                justifyContent='center' 
                my='auto'
              >
                <Typography variant='h4'>
                  {t('Δεν υπάρχουν ενεργοί επαγγελματίες')}
                </Typography>
              </Box>
              : (
                <Grid
                  container
                  spacing={2}
                  height='fit-content'
                  width='100%'
                  mb={2}
                >
                  {professionals.map((professional) => {
                    return (
                      <Grid 
                        key={professional.id} 
                        item 
                        xs={12} 
                        md={6}
                      >
                        <ProfessionalCard
                          sendMessage={(id: number, email: string) =>
                            sendMessage(email)
                          }
                          key={professional.id}
                          first_name={professional.first_name}
                          last_name={professional.last_name}
                          id={professional.id}
                          description={professional.description}
                          is_favourite={professional.is_favourite}
                          specialty={professional.specialty}
                          distance={professional.distance}
                          email={professional.email}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )
            }
          </Box>
        </Box>
      </Box>
      <Modal
        content={Map}
        open={openMap}
        handleClose={() => setOpenMap(false)}
        title={t('Χάρτης χρηστών')}
        fullWidth
        mode='info'
        maxWidth='md'
      />
      <AsyncBackdrop open={openBackdrop} />
    </Box>
  );
};

export default HomePage;
