import { openDB, deleteDB } from 'idb';
import { User } from '../components/context/Auth';

// Initialize IndexedDB and store the user profile
export const saveUserProfile = async (profile: User) => {  
  const db = await openDB('user-database', 1, {
    upgrade(db) {
      db.createObjectStore('profile', { keyPath: 'email' });
    },
  });
  await db.put('profile', profile);
};

// Fetch the user profile from IndexedDB
export const getUserProfile = async (email: string) => {
  const db = await openDB('user-database', 1);
  const profile = await db.get('profile', email);
  return profile;
};

export const deleteUserProfile = () => {
  deleteDB('user-database');
};