import { Avatar, Badge, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { Timestamp, arrayUnion, doc, increment, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Chat from '../components/Messenger/Chat';
import { IMessage } from '../components/Messenger/types';
import { useConversaionsContext } from '../components/context/Conversations';
import { useFirebaseContext } from '../components/context/FirebaseAuth';
import SidebarMessenger, { drawerWidth } from '../layout/Sidebar/SidebarMessenger';
import { db } from '../services/firebase';
import { containsOnlySpaces, getRemainingSubstring } from '../utils/helperFunctions';
import Theme from '../Themes/main.theme';
import { KeyboardBackspace } from '@mui/icons-material';
import ConversationList from '../components/ConversationList/ConversationList';
import { useTranslation } from 'react-i18next';

interface FirebaseMessage {
  date: Timestamp,
  id: string,
  message: string
  senderId: string
}

const Messenger = () => {
  const { t } = useTranslation();
  const firebaseUser = useFirebaseContext();
  const { conversations, loadingConversations } = useConversaionsContext();
  const matchesMD = useMediaQuery(() => Theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [messages, setMessages] = useState<IMessage[]>([]);

  const [searchParams] = useSearchParams();
  const conversationID = searchParams.get('mid');

  const sendMessage = async (message: string) => {
    if (!message || containsOnlySpaces(message) || !firebaseUser || !conversationID) return;

    const newMessage = {
      id: uuidv4(),
      message,
      senderId: firebaseUser.uid,
      date: Timestamp.now()
    };

    await updateDoc(doc(db, 'chats', conversationID), {
      messages: arrayUnion(newMessage),
    });
    await updateDoc(doc(db, 'userChats', firebaseUser.uid), {
      [conversationID + '.date']: serverTimestamp(),
    });

    await updateDoc(doc(db, 'userChats', getRemainingSubstring(conversationID, firebaseUser.uid)), {
      [conversationID + '.userInfo']: {
        uid: firebaseUser.uid,
        displayName: firebaseUser.displayName,
      },
      [conversationID + '.incoming_unread']: increment(1),
      [conversationID + '.show_chat']: true,
      [conversationID + '.date']: serverTimestamp(),
    });
  };

  const handleOnChatClick = (conversationID: string, unread_message: number) => {
    if (!firebaseUser) return;

    if (unread_message > 0) {  
      updateDoc(doc(db, 'userChats', firebaseUser.uid), {
        [conversationID + '.incoming_unread']: 0,
      });
    }
    navigate(`/messenger?mid=${conversationID}`);
  };

  // get chat Messages -> onSnapshot watches real time the messages from firebase
  useEffect(() => {
    const getMessages = (conversation_id: string) => {
      const unsubscribe = onSnapshot(doc(db, 'chats', conversation_id), (doc: any) => {
        doc.exists() && setMessages(doc.data()?.messages?.map((message: FirebaseMessage) => {
          const date = new Date(message.date.seconds * 1000 + message.date.nanoseconds / 1e6);
          return {
            message_text: message.message,
            time_sent: moment(date).format('DD/MM/yyyy HH:mm:ss'),
            own: message.senderId === firebaseUser?.uid,
          };
        }));
      });
      return () => {
        unsubscribe();
      };
    };

    conversationID && getMessages(conversationID);
  }, [conversationID, firebaseUser]);

  return (
    <>
      {conversationID
        ? <Chat messages={messages} handleSubmitMessage={sendMessage} />
        : matchesMD
          ? <Box
            height='90%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            width={{ xs: '100%', md: `calc(100% - ${drawerWidth}px)` }}
          >
            <Typography variant='h3' color='text.secondary'>
              {t('Επιλέξτε μία συνομιλία')}
            </Typography>
          </Box>
          : <ConversationList 
            conversationID={conversationID}
            conversations={conversations}
            onChatClick={handleOnChatClick}
          />
      }
      <SidebarMessenger 
        conversations={conversations
          .sort((a,b) => a.latest_message_date < b.latest_message_date
            ? 1
            : -1
          )} 
        loadingConversations={loadingConversations} 
      />
    </>
  );
};

export default Messenger;
