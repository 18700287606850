import AutoCompleteSelect from './AutocompleteSelect/AutoCompleteSelect';
import FormDatePicker from './DatePicker/FormDatePicker';
import Input from './Input/Input';
import StyledInput from './Styled/StyledInput';
import StyledPassword from './Styled/StyledPassword';
import TextArea from './TextArea/TextArea';
import { IFormController } from './types';

const FormController = (props: IFormController) => {
  if (!props || !props.formik) {
    return <></>;
  }

  const {
    control,
    hidden = false,
    errors = props.formik.errors[props.name] &&
      props.formik.touched[props.name],
    ...rest
  } = props;

  let SelectedComp = <></>;

  switch (control) {
  case 'input': {
    SelectedComp = <Input {...rest} />;
    break;
  }
  case 'styled-input': {
    SelectedComp = <StyledInput {...rest} />;
    break;
  }
  case 'password': {
    SelectedComp = <StyledPassword control={control} {...rest} />;
    break;
  }
  case 'textarea': {
    SelectedComp = <TextArea {...rest} />;
    break;
  }
  case 'select': {
    SelectedComp = <AutoCompleteSelect {...rest} />;
    break;
  }
  case 'date': {
    SelectedComp = <FormDatePicker {...rest} />;
    break;
  }

  default: {
    SelectedComp = <></>;
    break;
  }
  }

  return <>{!hidden && SelectedComp}</>;
};

export default FormController;
