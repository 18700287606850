import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IModal } from './types';
import AsyncButton from '../AsyncButton/AsyncButton';
import { Box, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import Theme from '../../Themes/main.theme';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const Modal = (props: IModal) => {
  const {
    open, 
    handleClose,
    title,
    content,
    onAccept = () => {},
    fullWidth=false,
    disableAcceptButton=false,
    isLoading=false,
    mode,
    maxWidth='sm'
  } = props;

  const matchesMD = useMediaQuery(() => Theme.breakpoints.up('md'));
  const { t } = useTranslation();

  return (
    <Dialog open={open} 
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={!matchesMD}
    >
      <Box display='flex'>
        <DialogTitle fontWeight='bold' id='alert-dialog-title'
          variant='h3'>
          {title}
        </DialogTitle>
        <Box display='flex' 
          marginLeft='auto'
          marginRight={2}>
          <IconButton onClick={handleClose} >
            <Close fontSize='small' htmlColor='black'/>
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {content}
      </DialogContent>
      {mode !== 'info' && (
        <DialogActions sx={{ display: 'flex', gap: 2, mr: 2}}>
          <Button onClick={handleClose} variant='outlined'>{t('Ακύρωση')}</Button>
          <AsyncButton 
            isLoading={isLoading}
            title={t('Επιβεβαίωση')}
            disabled={disableAcceptButton}
            onClick={onAccept} 
          />
        </DialogActions>
      )
      }
    </Dialog>
  );
};

export default Modal;