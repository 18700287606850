import { Box, Button, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './Chat.css';
import Message from './Message';
import { IChat } from './types';
import Theme from '../../Themes/main.theme';
import { useEffect, useRef, useState } from 'react';
import { drawerWidth } from '../../layout/Sidebar/SidebarMessenger';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ScrollbarBox } from '../ScrollbarBox/ScrollbarBox';
import { KeyboardBackspace } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Chat = (props: IChat) => {
  const { messages, handleSubmitMessage } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const matchesMD = useMediaQuery(() => Theme.breakpoints.up('md'));

  const [searchParams] = useSearchParams();
  const conversationID = searchParams.get('mid');

  const textfieldRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [error, setError] = useState<string>();

  const submitMessage = () => {
    setError('');
    // Handle Spam
    // Not allow above consecutive 4 messages in 2minutes
    const timedMessages = messages.slice(-4).filter(m => {
      const timeSent = moment(m.time_sent, 'DD/MM/YYYY HH:mm:ss');      
      return moment().diff(timeSent, 'minutes') < 2 && m.own;
    });

    if (timedMessages.length === 4) return setError('Περιμένετε 2 λεπτά από το τελευταίο μήνυμα μέχρι να στείλετε το επόμενο μήνυμα');

    handleSubmitMessage((textfieldRef.current as HTMLInputElement)?.value);
    (textfieldRef.current as HTMLInputElement).value = '';
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      submitMessage();
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  
  useEffect(() => {
    setError('');
    (textfieldRef.current as HTMLInputElement).value = '';
  }, [conversationID]);

  return (
    <>
      {!matchesMD && <IconButton onClick={() => navigate(-1)} sx={{ mb: 1}}>
        <KeyboardBackspace />
      </IconButton>}
      <Box className='chatBox' width={{ xs: '100%', md: `calc(100% - ${drawerWidth}px)`, xl: '100%' }} >
        <Box className='chatBoxWrapper'>
          <ScrollbarBox className='chatBoxTop'>
            {
              messages.length === 0 
                ? <Typography variant='subtitle1' sx={{ display: 'flex', justifyContent: 'center', color: Theme.palette.secondary.main }}>
                  {t('Ξεκινήστε την συνομιλία')}
                </Typography> 
                : messages.map((message, index) => {
                  return (
                    <div
                      key={index}
                      ref={scrollRef} 
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Message message={message} />
                    </div>
                  );
                })
            }
          </ScrollbarBox>
          {error && (
            <Typography variant='body1' color='red'>{t(error)}</Typography>
          )}
          <Box className='chatBoxBottom'>
            <TextField
              onKeyDown={handleKeyPress}
              inputRef={textfieldRef}
              fullWidth
              id='outlined-uncontrolled'
              placeholder={t('Μήνυμα')}
              multiline
              rows={3}
            />
            {matchesMD
              ? <Button className='chatSubmitButton'
                onClick={submitMessage}
                variant='contained'
                color='primary'
                endIcon={<SendIcon />}
              >
                {t('Αποστολή')}
              </Button>
              : <IconButton color='primary' onClick={submitMessage}>
                <SendIcon />
              </IconButton>
            }
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Chat;