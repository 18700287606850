import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FieldTypes, IFormController } from '../types';
import { useTranslation } from 'react-i18next';

const Input = (props: IFormController) => {
  const {
    label,
    name,
    formik,
    required,
    info,
    endAdornment = <></>,
    startAdornment,
    ...rest
  } = props;
  const { t } = useTranslation();
  const errors = formik?.errors[name] as string;

  const [value, setValue] = useState<number | null>(null);

  const handleChange = (event: any) => {
    const inputValue = event.target.value;
    let newValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (newValue === '') {
      newValue = '';
    } else {
      if (parseInt(newValue) < 0) {
        // Input is negative, reset to 0
        newValue = 0;
      }
      newValue = parseInt(newValue);
    }

    setValue(newValue);
    formik?.setFieldValue(name, newValue);
  };

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        {props.customLabel
          ? (
            <InputLabel
              disabled={props.disabled}
              htmlFor={name}
              required={required}
              sx={{
                my: 2,
                textAlign: 'left',
                textStyle: 'none',
              }}
            >
              {label}
            </InputLabel>
          )
          : null}
      </Box>
      <TextField
        {...(props.type === FieldTypes.password && { autoComplete: 'current-password' })}
        required={required}
        label={!props.customLabel
          ? label
          : null}
        sx={{
          width: '100%',
          minWidth: 200,
        }}
        InputProps={{
          ...(props.type === 'number' &&
            props.positives_only && {
            inputProps: {
              min: 0,
              pattern: '[0-9]+',
            },
          }),
          ...(startAdornment && {
            startAdornment: (
              <InputAdornment position='start'>{startAdornment}</InputAdornment>
            ),
          }),
          endAdornment: (
            endAdornment
          ),
        }}
        id={name}
        value={value}
        {...formik?.getFieldProps(`${name}`)}
        {...(props.type === 'number' && props.positives_only && { onChange: handleChange })}
        {...rest}
      />
      {formik?.errors[name] && formik?.touched[name]
        ? (
          <Typography color='error' variant='body1'>
            {t(errors)}
          </Typography>
        )
        : null}
    </Box>
  );
};

export default Input;
