import {
  Box,
  Drawer,
  Skeleton,
  Toolbar,
  Typography
} from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ConversationList from '../../components/ConversationList/ConversationList';
import Modal from '../../components/Modal/Modal';
import { useFirebaseContext } from '../../components/context/FirebaseAuth';
import { db } from '../../services/firebase';
import { ISideBarMessenger } from './types';
import { useTranslation } from 'react-i18next';

export const drawerWidth = 280;

export default function SidebarMessenger({conversations, loadingConversations}: ISideBarMessenger) {
  const { t } = useTranslation();
  const firebaseUser = useFirebaseContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const conversationID = searchParams.get('mid');

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [conversationIdChosen, setConversationIdChosen] = useState<string>();

  const deleteConversation = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    try {
      
      if (!firebaseUser?.uid || !conversationIdChosen) throw new Error('Κάτι πήγε Λάθος!');
      
      updateDoc(doc(db, 'userChats', firebaseUser.uid), {
        [conversationIdChosen + '.show_chat']: false,
      });
      setOpenDelete(false);
      navigate('/messenger');
    } catch (error) {
      console.error(error);
    }
  };

  const handleChatClick = (conversationID: string, unread_message: number) => {
    if (!firebaseUser) return;

    if (unread_message > 0) {  
      updateDoc(doc(db, 'userChats', firebaseUser.uid), {
        [conversationID + '.incoming_unread']: 0,
      });
    }
    navigate(`/messenger?mid=${conversationID}`);
  };

  const handleDeleteChat = (conversationID: string) => {
    setConversationIdChosen(conversationID);
    setOpenDelete(true);
  };

  useEffect(() => {
    if (!firebaseUser) return;
    
    conversations.forEach((conversation) => {
      if (conversation.conversationID === conversationID && conversation.unread_message > 0) {
        updateDoc(doc(db, 'userChats', firebaseUser.uid), {
          [conversationID + '.incoming_unread']: 0,
        });
      }
    });
  }, [conversations, conversationID, firebaseUser]);

  return (
    <>
      <Drawer
        anchor='right'
        variant='permanent'
        sx={{
          display: { xs: 'none', md: 'block'},
          ml: 10,
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Typography
              mb={3}
              variant='h3'
              display='flex'
              justifyContent='center'
            >
              {t('Συνομιλίες')}
            </Typography>
            {loadingConversations
              ? <Skeleton 
                variant='rounded'
                width='100%'
                height={60} />
              : <ConversationList 
                conversationID={conversationID}
                conversations={conversations}
                onChatClick={handleChatClick}
                onDeleteChat={handleDeleteChat}
              />
            }
          </Box>
        </Box>
      </Drawer>
      <Modal
        content={
          <Typography>
            {t('Είστε σίγουρος ότι θέλετε να κλείσετε την συνομιλία;')}
          </Typography>
        }
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        onAccept={deleteConversation}
        title={t('Διαγραφή συνομιλίας')}
        fullWidth
      />
    </>
  );
}
