import { FilterAlt } from '@mui/icons-material';
import { Box, Drawer, Fab } from '@mui/material';
import React from 'react';
import { FilterOptions } from '../SideMenu/SideMenu';
import { IBasicJSON } from '../../interfaces/generals.interface';

const ResponsiveMenu = ({ specialties }: { specialties: IBasicJSON[] }) => {
  const [drawerState, setDrawerState] = React.useState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerState(open);
  };

  return (
    <>
      <Fab
        sx={{
          position: 'fixed',
          zIndex: 1000,
          left: 300,
          bottom: 10,
        }}
        color='secondary'
        onClick={() => setDrawerState(true)}
      >
        <FilterAlt />
      </Fab>
      <Drawer
        anchor='left'
        open={drawerState}
        onClose={toggleDrawer(false)}
        sx={{ display: { sm: 'none' } }}
        PaperProps={{
          sx: { backgroundColor: 'rgb(247, 247, 247)', pl: 1, pt: 10 },
        }}
      >
        <FilterOptions specialties={specialties} />
      </Drawer>
    </>
  );
};

export default ResponsiveMenu;
