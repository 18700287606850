import { useEffect, useState } from 'react';
import { IBasicJSON } from '../interfaces/generals.interface';
import HttpService from '../services/HttpService';

const useSpecialties = () => {
  const [specialties, setSpecialties] = useState<IBasicJSON[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchSpecialties = async() => {
    setLoading(true);
    try {
      const response = await HttpService.get('/constants/specialties');
      setSpecialties(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    
    fetchSpecialties();
    
    return () => {
      setSpecialties([]);
    };

  }, []);

  return {
    specialties,
    loading
  };
};

export default useSpecialties;