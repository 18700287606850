import { TFunction } from 'i18next';
import { FieldControllerTypes, FieldTypes, IFormController } from '../../components/forms/types';
import { IBasicJSON } from '../../interfaces/generals.interface';

export const loginFields: IFormController[] = [
  {
    control: FieldControllerTypes['styled-input'],
    label: 'E-mail',
    name: 'email',
    required: true,
  },
  {
    control: FieldControllerTypes.password,
    label: 'Κωδικός',
    name: 'password',
    required: true,
    type: FieldTypes.password
  },
];

export const registerFields = (t: TFunction): IFormController[] => [
  {
    control: FieldControllerTypes.input,
    label: 'E-mail',
    name: 'email',
    required: true,
  },
  {
    control: FieldControllerTypes.input,
    label: t('Κωδικός'),
    name: 'password',
    required: true,
    type: FieldTypes.password,
    helperText: t('Τουλάχιστον 8 χαρακτήρες')
  },
  {
    control: FieldControllerTypes.input,
    label: t('Επιβεβαίωση Κωδικού'),
    name: 'confirmPassword',
    required: true,
    type: FieldTypes.password,
  },
];

export const registerFieldsStep2 = (t: TFunction): IFormController[] => [
  {
    control: FieldControllerTypes.input,
    label: t('Όνομα'),
    name: 'first_name',
  },
  {
    control: FieldControllerTypes.input,
    label: t('Επώνυμο'),
    name: 'last_name',
  },
  {
    control: FieldControllerTypes.date,
    label: t('Ημερομηνία γέννησης'),
    name: 'dob',
  },
];

export const registerFieldsStep3 = (specialties: IBasicJSON[], t: TFunction): IFormController[] => [
  {
    control: FieldControllerTypes.select,
    label: t('Ειδικότητα'),
    name: 'specialty',
    options: specialties
  },
  {
    control: FieldControllerTypes.textarea,
    label: t('Περιγραφή'),
    name: 'description',
  }
];

export const changePasswordFields = (t: TFunction): IFormController[] => [
  {
    control: FieldControllerTypes.input,
    label: 'Email',
    name: 'email',
    disabled: true
  },
  {
    control: FieldControllerTypes.input,
    label: t('Κωδικός'),
    name: 'password',
    type: FieldTypes.password,
  },
  {
    control: FieldControllerTypes.input,
    label: t('Καινούριος Κωδικός'),
    name: 'new_password',
    type: FieldTypes.password,
    helperText: t('Τουλάχιστον 8 χαρακτήρες')
  }
];