import { doc, onSnapshot } from 'firebase/firestore';
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import { IConversations } from '../../interfaces/professionals.interface';
import { db } from '../../services/firebase';
import { useFirebaseContext } from './FirebaseAuth';
import { useLocation } from 'react-router-dom';

export const ConversaionsContext = createContext<{
  conversations: IConversations[],
  loadingConversations: boolean
}>({conversations: [], loadingConversations: true});

export const ConversaionsContextProvider = ({children}: any) => {
  const [conversations, setConversations] = useState<IConversations[]>([]);
  const [loadingConversations, setLoadingConversations] = useState<boolean>(true);

  const { pathname } = useLocation();

  const firebaseUser = useFirebaseContext();
  
  const flashTitle = (pageTitle: string, newTitle: string) => {
    document.title === pageTitle
      ? (document.title = newTitle)
      : (document.title = pageTitle);
  };

  //find user's conversations for sidebar
  useEffect(() => {
    
    const getUserConversations = async (firebaseUserUid: string) => {

      const unsub = onSnapshot(doc(db, 'userChats', firebaseUserUid), (doc) => {
        const _conversations = doc.exists()
          ? Object.keys(doc.data())
            .filter(conversationID => (
              doc.data()[conversationID]?.userInfo?.uid !== firebaseUserUid && 
              doc.data()[conversationID]?.show_chat
            ))
            .map(conversationID => {
              const date = new Date(
                doc.data()[conversationID]?.date?.seconds * 1000 + 
                doc.data()[conversationID]?.date?.nanoseconds / 1e6
              );
              return {
                conversationID,
                member: { displayName: doc.data()[conversationID]?.userInfo?.displayName || '' },
                latest_message_date: moment(date).format('DD/MM/yyyy HH:mm:ss'),
                unread_message: doc.data()[conversationID]?.incoming_unread || 0
              };
            })
          : [];
        
        setConversations(_conversations);
      });

      setLoadingConversations(false);
      return () => {
        unsub();
      };
    };
    
    (firebaseUser?.uid && pathname !== '/register' && pathname !== '/login') && getUserConversations(firebaseUser?.uid);
  }, [firebaseUser?.uid, pathname]);

  // flash tab title if unread conversations
  useEffect(() => {
    const totalUnreadConversations = conversations.filter(conv => conv.unread_message > 0).length;
    const intervalId = totalUnreadConversations > 0 
      ? setInterval(() => flashTitle('My-Xpert', `(${totalUnreadConversations}) New Message | My-Xpert`), 1500)
      : document.title = 'My-Xpert';
    return () => clearInterval(intervalId);

  }, [conversations]);

  return (
    <ConversaionsContext.Provider value={{conversations, loadingConversations}}>
      {children}
    </ConversaionsContext.Provider>
  );
};

ConversaionsContextProvider.context = ConversaionsContext;

export const useConversaionsContext = () => useContext(ConversaionsContext);