import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import { IProfessionalCard } from './card.interface';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import Theme from '../../Themes/main.theme';
import HttpService from '../../services/HttpService';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProfessionalCard = (props: IProfessionalCard) => {
  const {
    id,
    description,
    is_favourite,
    first_name,
    specialty,
    last_name,
    distance,
    sendMessage,
    email,
  } = props;
  const { t } = useTranslation();

  const [iFavourite, setIsFavourite] = useState(is_favourite);
  
  const navigate = useNavigate();

  const changeFavouriteStatus = useCallback(async (id: number) => {
    try {
      await HttpService.put(`/users/favourites/${id}`);
      setIsFavourite((prevFavourite) => !prevFavourite);
    } catch (error) {
      console.error(error);
    }
  }, []) ;

  return (
    <Card
      className='card'
      elevation={5}
      sx={{ minWidth: 150, maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}
      onClick={() => navigate(`/users/${id}`)}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: Theme.palette.secondary.main }}
            aria-label='recipe'
          >
            {first_name?.split('')[0].toUpperCase()}
          </Avatar>
        }
        title={`${first_name || ''} ${last_name || ''}`}
        subheader={
          <Box>
            <div>{specialty}</div>
            {distance !== undefined && <div style={{ marginTop: '5px'}}>{Math.floor(distance)} {t('Μέτρα')}</div>}
          </Box>
        }
      />
      <CardContent>
        <Typography 
          variant='body2'
          color='text.secondary'
        >
          {description || t('Δεν υπάρχει περιγραφή')} 
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ mt: 'auto' }}>
        <IconButton
          aria-label='add to favorites'
          onClick={(e) => {
            e.stopPropagation();
            changeFavouriteStatus(id);
          }}
        >
          {
            iFavourite
              ? <FavoriteIcon color='error' />
              : <FavoriteBorderIcon />
          }
        </IconButton>
        {sendMessage && (
          <IconButton
            aria-label='share'
            onClick={(e) => {
              e.stopPropagation();
              sendMessage(id, email);
            }}
          >
            <Tooltip title={t('Αποστολή μηνύματος')}>
              <SendIcon color='primary' />
            </Tooltip>
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default ProfessionalCard;
