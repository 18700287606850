import { Language } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = ({color = 'white'}) => {
  const { i18n, t } = useTranslation();
  
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);

  const handleCloseLang = () => setAnchorElLang(null);

  const changeLanguage = (lng: 'el' | 'en') => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <>
      <IconButton onClick={(e) => setAnchorElLang(e.currentTarget)}>
        <Language htmlColor={color}/>
      </IconButton>
      <Menu
        anchorEl={anchorElLang}
        id='language-menu'
        open={openLang}
        onClose={handleCloseLang}
        onClick={handleCloseLang}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 5,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem selected={i18n.language === 'el'} onClick={() => changeLanguage('el')}>
          <Box 
            display='flex' 
            alignItems='center'
            gap={1}
          >
            <img
              loading='lazy'
              width='20'
              srcSet={'https://flagcdn.com/w40/gr.png 2x'}
              src={'https://flagcdn.com/w20/gr.png'}
              alt=''
            />
            {t('Ελληνικά')}
          </Box>
        </MenuItem>
        <MenuItem selected={i18n.language === 'en'} onClick={() => changeLanguage('en')}>
          <Box display='flex' alignItems='center'
            gap={1}>
            <img
              loading='lazy'
              width='20'
              srcSet={'https://flagcdn.com/w40/gb.png 2x'}
              src={'https://flagcdn.com/w20/gb.png'}
              alt=''
            />
            {t('Αγγλικά')}
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSelector;