import { Language } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../App.css';
import backGroundImage from '../assets/pexels-brayden-law-2096700.jpg';
import AsyncButton from '../components/AsyncButton/AsyncButton';
import { useAuthContext } from '../components/context/Auth';
import FormActionButtonLayout from '../components/forms/FormActionButtonLayout';
import FormBuilder from '../components/forms/FormBuilder';
import FormLayout from '../components/forms/FormLayout';
import HttpService from '../services/HttpService';
import { isSubmitEnabled } from '../utils/helperFunctions';
import { loginFields } from '../utils/static/filterFields';
import { loginInitialValues } from '../utils/static/initialValues';
import { loginValidationschema } from '../utils/static/validationSchema';
import LanguageSelector from '../components/LanguageSelector/LanguageSelector';

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const { login } = useAuthContext();

  const onLogin = async (values: { email: string, password: string }) => {
    setIsLoading(true);
    try {
      const response = await HttpService.post('/users/login', values);
      login(response.data.access_token);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backGroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh !important',
      }}
    >
      <Box minWidth='30%'>
        <div className='glass-effect' style={{ border: '1px solid white' }}>
          <Box display='flex' 
            flexDirection='column'
            alignItems='center'>
            <Typography
              variant='body1' 
              fontSize='30px'
              color='rgb(247, 247, 247)'>
              {t('Σύνδεση')}
            </Typography>
          </Box>
          <Formik
            onSubmit={() => undefined}
            initialValues={loginInitialValues}
            validationSchema={loginValidationschema}
            validateOnBlur={false}
          >
            {(formik) => (
              <>
                <Form>
                  <FormLayout>
                    <FormBuilder fields={loginFields} formik={formik}/>
                  </FormLayout>
                  <FormActionButtonLayout>
                    <AsyncButton
                      gradient
                      title={t('Σύνδεση')}
                      isLoading={isLoading}
                      onClick={() => onLogin(formik.values)}
                      disabled={isSubmitEnabled(formik?.errors, formik?.dirty)}
                    />
                  </FormActionButtonLayout>
                </Form>
              </>
            )}
          </Formik>
          <Box sx={{
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <LanguageSelector />
            <Link
              to='/register'
              style={{
                textDecorationColor: 'white',
                color: 'white'
              }}
            >
              <Typography variant='body1'>
                {t('Δεν έχετε λογαριασμό;')}
              </Typography>
            </Link>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default LoginPage;
