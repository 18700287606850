import { Avatar, Badge, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { IConversations } from '../../interfaces/professionals.interface';
import Theme from '../../Themes/main.theme';
import { HighlightOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ConversationList = (props: {
    conversations: IConversations[];
    conversationID: string | null;
    onChatClick: (conversationID: string, unread_message: number) => void;
    onDeleteChat?: (conversationID: string) => void
  }) => {
  const { conversationID, conversations, onChatClick, onDeleteChat } = props;
  const { t } = useTranslation();

  return conversations.length > 0
    ? <List sx={{ width: '100%' }}>
      {conversations.map((conversation, index) => {
        return (
          <span key={conversation.conversationID}>
            <ListItemButton
              selected={conversation.conversationID === conversationID}
              onClick={() => onChatClick(conversation.conversationID, conversation.unread_message)}
            >
              <ListItem>
                <ListItemAvatar>
                  <Badge 
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    color='error'
                    overlap='circular' 
                    badgeContent={conversation.unread_message} 
                    invisible={conversation.unread_message === 0}
                  >
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        p: 2,
                        backgroundColor: Theme.palette.secondary.main,
                      }}
                    >
                      {!conversation?.member?.displayName
                        ? '-'
                        : conversation.member.displayName
                          .split('')[0]
                          .toUpperCase()}
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={conversation.member?.displayName || '-'}
                  secondary={conversation.latest_message_date}
                />
              </ListItem>
              {onDeleteChat && <ListItemIcon onClick={(event) => {
                event.stopPropagation();
                onDeleteChat(conversation.conversationID);
              }}>
                <HighlightOff color='secondary'/>
              </ListItemIcon>}
            </ListItemButton>
            {index < conversations.length - 1 && (
              <Divider variant='middle' color={Theme.hoverColor?.main}/>
            )}
          </span>
        );
      })
      }
    </List>
    : <>
      <Typography
        mb={3}
        variant='body1'
        color='GrayText'
        display='flex'
        justifyContent='center'
      >
        {t('Δεν έχετε ξεκινήσει')}<br/>{t('κάποια συνομιλία')}
      </Typography>
      <Typography
        mb={3}
        variant='body2'
        color='GrayText'
        display='flex'
        justifyContent='center'
      >
        <span>
          {t('Επιλέξτε από την')} <Link to='/'>{t('αναζήτηση')}</Link> <br/>{t('κάποιον επαγγλεματία')}
        </span>
      </Typography>
    </>;
};

export default ConversationList;